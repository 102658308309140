export const FONT_LIST = [
  { name: 'THSarabunNew', value: 'THSarabunNew' },
  { name: 'THSarabunNewBold', value: 'THSarabunNewBold' },
  { name: 'THSarabunNewItalic', value: 'THSarabunNewItalic' },
  { name: 'THSarabunNewBoldItalic', value: 'THSarabunNewBoldItalic' },
  { name: 'Cordia', value: 'Cordia' },
  { name: 'CordiaBold', value: 'CordiaBold' },
  { name: 'CordiaItalic', value: 'CordiaItalic' },
  { name: 'CordiaBoldItalic', value: 'CordiaBoldItalic' },
  { name: 'Gothic', value: 'Gothic' },
  { name: 'GothicBold', value: 'GothicBold' },
  { name: 'GothicItalic', value: 'GothicItalic' },
  { name: 'GothicBoldItalic', value: 'GothicBoldItalic' },
];

export const DATE_TYPE_LIST = [
  {
    value: 'published_at',
    label: 'Publish Date',
    th: 'วันที่เผยแพร่',
  },
  {
    value: 'finished_at',
    label: 'Finished Date',
    th: 'วันที่เสร็จสิ้น',
  },
  {
    value: 'custom_date',
    label: 'Custom Date',
    th: 'กำหนดวันที่เอง',
  },
];

export const SIGNATURE_COLOR_LIST = [
  '#000000',
  '#4169e1',
  '#EB2B36',
  '#266D1F',
];
